import { useMemo } from 'react';

import SvgColor from '@/components/SvgColor';
import { paths } from '@/constants';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  blog: icon('ic_blog'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  banking: icon('ic_banking'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

export function useNavData() {
  return useMemo(
    () => [
      {
        items: [
          {
            title: 'Home',
            path: paths.home,
            icon: ICONS.dashboard,
          },
          {
            title: 'Orders',
            path: paths.orders,
            icon: ICONS.file,
          },
          // { title: 'Lenders', path: paths.lenders, icon: ICONS.banking },
          // { title: 'Vendors', path: paths.vendors, icon: ICONS.blog },
          // {
          //   title: 'Reports',
          //   path: paths.reports,
          //   icon: ICONS.analytics,
          // },
        ],
      },
    ],
    []
  );
}
