import { init as initFullStory } from '@fullstory/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SnackbarProvider } from '@/components/snackbar/';
import { SettingsProvider } from '@/context/settings';
import ThemeProvider from '@/theme';

import { AppRoutes } from './routes';

const queryClient = new QueryClient();
const fullstoryOrgId = import.meta.env.VITE_FULLSTORY_ORG_ID;

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

if (fullstoryOrgId) {
  initFullStory({ orgId: fullstoryOrgId });
}

const Router = () => {
  const routes = useRoutes(AppRoutes);
  return routes;
};

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeLayout: 'vertical',
            }}
          >
            <ThemeProvider>
              <SnackbarProvider>
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>
);
