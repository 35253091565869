import * as React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, Theme } from '@mui/material/styles';

import RouterLink from '@/components/RouterLink';
import { useSettingsContext } from '@/context/settings';

import { useNavData } from './useNavData';

export default function NavList() {
  const navData = useNavData();

  const { pathname } = useLocation();

  const settings = useSettingsContext();

  const isVerticalNav = settings.themeLayout === 'vertical';

  return (
    <List>
      {navData.map((navSection) =>
        navSection.items.map((navItem, index) => {
          const isActive = matchPath(navItem.path, pathname) !== null;

          const activeColor = (theme: Theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.primary.main
              : theme.palette.primary.light;

          return (
            <Box key={index} sx={{ m: 1 }}>
              <Link component={RouterLink} href={navItem.path} color="inherit" underline="none">
                <ListItem disablePadding>
                  <ListItemButton
                    sx={(theme) => ({
                      flexDirection: isVerticalNav ? 'row' : 'column',
                      borderRadius: 1,
                      color: isActive ? activeColor(theme) : 'inherit',
                      backgroundColor: isActive
                        ? alpha(theme.palette.primary.main, 0.08)
                        : 'transparent',
                      '&:hover': {
                        backgroundColor: isActive
                          ? alpha(theme.palette.primary.main, 0.16)
                          : theme.palette.action.hover,
                      },
                    })}
                  >
                    <ListItemIcon
                      sx={{
                        color: isActive ? 'primary' : 'text.secondary',
                      }}
                    >
                      <Box
                        sx={{
                          width: 24,
                          ml: isVerticalNav ? 0 : 1.5,
                        }}
                      >
                        {navItem.icon}
                      </Box>
                    </ListItemIcon>

                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body2',
                        fontWeight: 500,
                        color: isActive ? 'primary' : 'text.secondary',
                      }}
                    >
                      {navItem.title}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            </Box>
          );
        })
      )}
    </List>
  );
}
