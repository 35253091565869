import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Logo from '@/components/logo/Logo';
import { NAV } from '@/constants';
import { hideScroll } from '@/theme/css';

import NavList from './NavList';
import NavToggleButton from './NavToggleButton';

export default function NavMini() {
  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Box
          sx={{
            pl: 3,
            py: 2.5,
          }}
        >
          <Logo small image="/assets/nvs-logo-sm.png" />
        </Box>

        <NavList />
      </Stack>
    </Box>
  );
}
