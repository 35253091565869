import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navigate } from 'react-router-dom';

export default function PermissionGuard({
  permission,
  value,
  children,
  redirect,
}: {
  permission?: string;
  value?: string;
  children: React.ReactNode;
  redirect?: string;
}) {
  const { user } = useAuth0();

  const hasPermission = permission
    ? user && user['https://xpanse.com/claims/security/policy']?.[permission]
    : true;

  const hasValue =
    permission && value
      ? user && user['https://xpanse.com/claims/security/policy']?.[permission] === value
      : true;

  if (!hasPermission || !hasValue) {
    return redirect ? <Navigate to={redirect} /> : null;
  }

  return <>{children}</>;
}
